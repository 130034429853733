<template>
  <v-card outlined>
    <v-card-text>
      <div class="mb-2">
        {{ $t('modules.customdata.onboarding_screen.create_fast_data.title') }}
      </div>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-radio-group
          v-model="nameModel"
          row
          hide-details
        >
          <v-radio
            :label="$t('modules.customdata.onboarding_screen.create_fast_data.option_city')"
            value="data_world_cities"
          ></v-radio>
          <v-radio
            :label="$t('modules.customdata.onboarding_screen.create_fast_data.option_empty')"
            value="data_default"
          ></v-radio>
        </v-radio-group>

        <transition name="fade" mode="in-out">
          <v-text-field
            v-if="nameModel"
            :value="nameModel"
            @input="inputModelName($event)"
            class="mt-4"
            outlined
            dense
            hide-details="auto"
            :label="$t('modules.customdata.model.name')"
            :rules="nameRules"
            :hint="
              $t('modules.customdata.model.name')
              + $t('modules.customdata.model.not_editable_after_creation')
            "
            append-outer-icon="mdi-table-plus"
            @click:append-outer="createModel"
          ></v-text-field>
        </transition>
      </v-form>

      <transition name="fade" mode="in-out">
        <v-alert
          v-if="modelEnumCreated && !nameModel"
          dense
          text
          type="success"
          class="mt-4"
        >
          <v-row align="center">
            <v-col class="grow">
              {{ $t('modules.customdata.onboarding_screen.create_fast_data.alert_text_1') }} <b>"{{name}}"</b> {{ $t('modules.customdata.onboarding_screen.create_fast_data.alert_text_2') }}!
            </v-col>
            <v-col class="shrink">
              <v-btn
                color="primary"
                link
                dense
                small
                elevation="0"
                @click="linkToModel()"
              >{{ $t('modules.customdata.onboarding_screen.create_fast_data.alert_btn') }}</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </transition>
    </v-card-text>
  </v-card>
</template>

<script>

import i18n from "@/lang/i18n";
import {mapActions, mapGetters} from "vuex";
import Vue from "vue";
import axios from "axios";

export default {
  name: 'CreateFastData',

  props: {
    route_from: {
      type: String,
      default: '',
    }
  },

  data() {
    var self = this;
    return {
      tables: [],

      nameModel: null,

      valid: true,
      name: null,
      nameRules: [
        v => !!v || i18n.t('modules.customdata.model.name_required'),
        v => /^[A-Za-z0-9_]*$/.test(v) || i18n.t('modules.customdata.model.name_required'),
        v => self.checkTableNameExist(v) || i18n.t('modules.customdata.onboarding_screen.create_fast_data.rule_text_model_exist')
      ],

      modelEnumCreated: false,

      enum_data: {
        data_world_cities: {
          description: 'World Cities Data',
          json_obj: {
            "city": {
              "type": "STRING"
            },
            "admin_name": {
              "type": "STRING"
            },
            "country": {
              "type": "STRING"
            },
            "iso2": {
              "type": "STRING"
            },
            "iso3": {
              "type": "STRING"
            },
            "population": {
              "type": "STRING"
            }
          },
          file_url: "/worldCitiesCustomData.csv"
        },
        data_default: {
          description: 'Default Data',
          json_obj: {
            "minimal": {
              "type": "INTEGER"
            },
            "column_name": {
              "type": "STRING",
              "default":"default value",
              "unique":"true",
              "label":"custom name"
            }
          },
          file_url: false
        }
      },
    }
  },

  async mounted() {
    await this.CustomDataAxiosGetTables();
    this.tables = this.custom_data_tables;

    this.redirectToModel();
  },

  computed: {
    ...mapGetters([
      'custom_data_tables',
      'engine_settings',
      'bot_settings',
    ]),
  },

  methods: {
    ...mapActions([
      'CustomDataAxiosGetTables',
      'CustomDataAxiosCreateModel',
    ]),

    redirectToModel() { 
      const tablesExist = this.custom_data_tables.length > 0;

      if (this.route_from && this.route_from.indexOf('customdata') === -1 && tablesExist) {
        this.$router.push({ name: 'Models', params: { model_name: this.name } });
      }
    },

    checkTableNameExist(value) {
      return !this.tables.find((t) => t?.name === value)
    },

    async createModel() {
      await this.$refs.form.validate();
      if(!this.valid) return

      await this.CustomDataAxiosCreateModel({
        name: this.name || this.nameModel,
        description: this.enum_data[this.nameModel].description,
        columns_json: this.enum_data[this.nameModel].json_obj
      }).then(async (response) => {
        if(!response) return

        if(this.enum_data[this.nameModel].file_url) {
          fetch(this.enum_data[this.nameModel].file_url)
            .then((resp) => {
              if (!resp.ok) {
                throw new Error('Network response was not ok');
              }
              return resp.blob();
            })
            .then(async (fileBlob) => {
              await this._importFromFile(fileBlob)
              await this.CustomDataAxiosGetTables();
              this.tables = this.custom_data_tables;
              await this.$refs.form.validate()
            })
            .catch((error) => {
              console.error('Error fetching file', error);
            });
        } else {
          this.name = this.name || JSON.parse(JSON.stringify(this.nameModel));
          this.nameModel = null
          this.modelEnumCreated = true

          await this.CustomDataAxiosGetTables();
          this.tables = this.custom_data_tables;
          await this.$refs.form.validate()
        }
      });
    },

    async _importFromFile(fileBlob) {
      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;
      const url = `${engine_url}/kw/custom_modules/import_data_from_file`

      const formData = new FormData();

      formData.append('file', fileBlob, `${this.name || this.nameModel}.csv` );
      formData.append('bot_id', bot_id);
      formData.append('module_name', this.name || this.nameModel);

      await this.$store.dispatch('updateAjaxDialog', [true, true]);

      await axios
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            "X-CSRF-TOKEN": token
          },
        })
        .then((response) => {
          if ( response?.data?.status === 'success' ) {
            this.$store.dispatch('updateAjaxDialog',  [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.model.imported', {count:response?.data?.data?.inserted, total:response?.data?.data?.countFrom}) ]);
            this.name = this.name || JSON.parse(JSON.stringify(this.nameModel));
            this.nameModel = null
            this.valid = null

            this.modelEnumCreated = true
          }
        }, (err) => {
          this.$store.dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_get") ]);
        });
    },

    inputModelName(value) {
      this.name = value
      this.modelEnumCreated = false
    },

    linkToModel() {
      this.$router.push({ name: 'Models', params: {model_name: this.name} })
    }
  },
}
</script>

<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>