<template>
  <div class="cd_create">
    <v-btn
      v-show="!aiMagicDrawer"
      class="cd_create__btn-ai-magic"
      fixed
      top
      right
      color="primaryLighten"
      dark
      @click.stop="aiMagicDrawer = !aiMagicDrawer"
      elevation="0"
    >
      <v-icon left>mdi-magic-staff</v-icon>
      AI magic
      <div class='b_l_quad'>
        <div class='button_spots' :key="i" v-for="(item, i) in 51"></div>
      </div>
    </v-btn>

    <div class="cd_create_wrapper">
      <v-container class="cd_create_wrapper--top">
        <!--SELECT MODEL-->
        <v-select
          v-model="selectedTable"
          :items="[{id:-1,name:$t('modules.customdata.model.new')}].concat(custom_data_tables_select)"
          item-text="name"
          item-value="id"
          :placeholder="$t('modules.customdata.select_model')"
          :hint="$t('modules.customdata.select_model')"
          persistent-hint
          outlined
          hide-details
          dense
          background-color="white"
          class="elevation-0"
        />
      </v-container>

      <div class="cd_create_wrapper--body">

        <v-form ref="form" v-model="valid">
          <v-container class="py-0">
            <v-layout>
              <v-switch
                class="mt-1 mr-3"
                v-model="activateRegUserId"
                @change="changeRegUserId"
                color="primary"
                hide-details
              >
                <template v-slot:label>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                      >mdi-card-account-details-outline</v-icon>
                    </template>
                    {{$t('modules.customdata.model.add_reg_user_id_label')}}
                  </v-tooltip>
                </template>
              </v-switch>

              <v-text-field
                :label="$t('modules.customdata.model.name')"
                v-model="name"
                :rules="nameRules"
                :disabled="selectedTable && selectedTable!==-1"
                :hint="
                  $t('modules.customdata.model.name') + ' ' +
                  $t('modules.customdata.model.not_editable_after_creation')
                "
                outlined
                dense
                background-color="white"
                class="mr-3"
              />
              <v-text-field
                :label="$t('modules.customdata.model.description')"
                v-model="description"
                :rules="descRules"
                outlined
                dense
                background-color="white"
              ></v-text-field>

            </v-layout>
          </v-container>

          <v-divider />

          <v-sheet
            height="300"
            color="gray5"
            class="py-1"
          >
          <vue-scroll>
            <v-container class="py-0">

              <p>{{$t('modules.customdata.model.json')}}</p>


                    <VJsoneditor
                      v-model="json_obj_in_editor"
                      :options="options"
                      :height="checkHeightJEditor(json_obj_in_editor)"
                    />


              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>{{$t('modules.customdata.model.json_example')}}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <pre class="json-example">
{
  "minimal":{"type":"INTEGER"},
  "column_name": {
    "type":"STRING", //Required, can be also "INTEGER" | "DATE" | "BOOLEAN" | "FLOAT" | "TEXT"
    "align":"start", //optional align of header in table
    "default":"default value", //optional
    "unique":"true"|"false", //optional
    "label":"custom name", //optional
    "width": "10", //column width
    "hidden_in_table":"true"|"false", // optional; if true, will be turned off in columns chooser
    "hidden_all":"true"|"false", // optional; if true, will be hidden in all contexts (except in filters)
    "sortable":"true"|"false", // optional; if true, column sortable in table
    "non_editable":"true"|"false", // optional; if true, column not editable in table
    "display_type":"link", // optional; if 'link', column has clickable link opening in new tab
    "click_action":"open", // optional; if 'open', column has button opening edit modal
    "file_upload": true, //optional, for file uploading; important only "type": "JSON",
    "association": {
      "*__color": "hex code" //for colorized in select field name in model which will be associated
    }
  }
}
                    </pre>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <v-divider class="my-5" />
              <p>{{$t('modules.customdata.model.json_statistics')}}</p>
              <VJsoneditor
                v-model="statistics_json_in_editor"
                :options="options"
                :height="checkHeightJEditor(statistics_json_in_editor)"
              />
            </v-container>
          </vue-scroll>
          </v-sheet>
        </v-form>
      </div>

      <v-divider />

      <div class="cd_create_wrapper--footer">
        <v-layout :justify-center="true" class="py-4">
          <v-btn
            :disabled="!(json_obj && valid)"
            color="primary"
            elevation="23"
            @click="createModel"
            small
          >{{ $t('modules.customdata.create') }}</v-btn>

          <v-btn
            v-if="selectedTable && selectedTable!==-1"
            elevation="23"
            color="error"
            class="ml-5"
            small
            @click="deleteModel"
          >
            <v-icon left>mdi-trash-can</v-icon>
            {{ $t('modules.customdata.delete') }}

          </v-btn>
        </v-layout>
      </div>
    </div>

    <v-navigation-drawer
      v-model="aiMagicDrawer"
      absolute
      right
      temporary
      light
      width="36%"
    >
      {{/** MODAL CLOSE BTN **/}}
      <v-btn
        class="modal__close-btn"
        icon
        small
        absolute
        top
        left
        @click="aiMagicDrawer = false"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
      {{/** END MODAL CLOSE BTN **/}}

      <v-sheet class="cd_create__drawer pa-6 pt-10">
        <CreateAiMagicDataForm
          integrated_mode="drawer"
        />
      </v-sheet>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import VJsoneditor from 'v-jsoneditor'
import i18n from '@/lang/i18n';
import CreateAiMagicDataForm from "@/modules/CustomData/components/CreateAiMagicDataForm.vue";

export default {
  name: "CreateTab",

   components: {
    CreateAiMagicDataForm,
    VJsoneditor
  },

  data: () => ({

    options:{ mode: 'code' },
    name:"",
    description:"",
    valid: false,
    selectedTable:null,
    tables:[],
    table:{},
    json_obj: {},
    statistics_json: {},
     nameRules: [
        v => !!v || i18n.t('modules.customdata.model.name_required'),
        v => /^[A-Za-z0-9_]*$/.test(v) || i18n.t('modules.customdata.model.name_required')
      ],

     descRules: [
        v => !!v || i18n.t('modules.customdata.model.description_required'),
        v => v.length>10 || i18n.t('modules.customdata.model.description_required')
      ],

    activateRegUserId: false,

    aiMagicDrawer: false,
  }),

  async mounted() {
    await this.CustomDataAxiosGetTables(this.$route?.params?.id);
    this.tables = this.custom_data_tables
  },

  watch: {
    selectedTable(t) {
      let tt_obj=null
      for(let tt of this.tables){
        if(tt.id === t){
          tt_obj=tt
          break;
        }
      }


      if(tt_obj!==null){
        this.name = tt_obj.name
        this.description = tt_obj.description
        this.json_obj = tt_obj.columns_json
        this.statistics_json = tt_obj?.statistics_json
        this.activateRegUserId = !!this.json_obj?.reg_user_id;

      }else{
          this.name = ""
        this.description =""
        this.json_obj = {}
        this.statistics_json = {}
        this.activateRegUserId = false
      }

    },

    json_obj: function(obj) {
      this.activateRegUserId = !!obj?.reg_user_id
    },
  },

  computed: {
    ...mapGetters([
      'dialog',
      'engine_settings',
      'bot_settings',
      'current_tab_type',
      'all_bot_data_init',
      'custom_data_tables_select',
      'custom_data_tables',
      'user',
    ]),

    json_obj_in_editor: {
      cache: false,
      get() {
        return this._.cloneDeep(this.json_obj);
      },
      set(value) {
        this.json_obj = value
      },
    },

    statistics_json_in_editor: {
      cache: false,
      get() {
        return this._.cloneDeep(this.statistics_json);
      },
      set(value) {
        this.statistics_json = value
      },
    },
  },

  methods: {
    ...mapActions(['CustomDataAxiosGetTables',
      'CustomDataAxiosCreateModel',
      'CustomDataAxiosDeleteModel']),

    async createModel(){
        if ( !this.json_obj?.reg_user_id && this.activateRegUserId) {
          this.json_obj['reg_user_id'] = {"type":"INTEGER", "unique": "true"}
        }

       await this.CustomDataAxiosCreateModel({
        name: this.name,
        description:this.description,
        columns_json:this.json_obj,
        statistics_json:this.statistics_json
      });
        await this.CustomDataAxiosGetTables(this.$route?.params?.id);
         this.tables = this.custom_data_tables

    },


    async deleteModel(){

       if (confirm(i18n.t('modules.customdata.model.confirm_delete'))) {
         await this.CustomDataAxiosDeleteModel({name: this.name})
         await this.CustomDataAxiosGetTables(this.$route?.params?.id);
         this.tables = this.custom_data_tables

         this.name = ""
         this.description = ""
         this.json_obj = {}
         this.statistics_json = {}
         this.activateRegUserId = false
       }

    },

    changeRegUserId(val) {
      if (val && !this.json_obj?.reg_user_id) {
        this.json_obj['reg_user_id'] = {"type":"INTEGER", "unique": "true"}
      } else {
        delete this.json_obj['reg_user_id']
      }
    },

    checkHeightJEditor(obj) {
      if(!obj) return '213px'
      return Object.keys(obj)?.length !== 0 ? '380px' : '213px'
    }

  },
};
</script>


<style lang="scss" scoped>
.cd_create {
  $this: 'cd_create';
  position: relative;
  overflow: hidden;

  &_wrapper {
    height: calc(100vh - 60px) !important;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &--top{}
    &--body{
      flex: 1 1 auto;

      .v-form {
        height: 100%;
        display: flex;
        flex-direction: column;

        .v-sheet {
          flex: 1 1 auto;
        }
      }
    }
    &--footer{}
  }

  &__drawer::v-deep {
    height: 100%;

    & > div {
      height: 100%;
      display: flex;
      flex-direction: column;

      .v-form {
        flex: 1 1 auto;
      }
      .v-btn {
        max-height: 45px;
      }
    }
  }

  &__btn-ai-magic {
    z-index: 10;
    top: 168px;
    right: 0;
    border-radius: 25px 0 0 25px;
    text-transform: inherit;

    &:before {
      content: '';
      background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
      position: absolute;
      top: -2px;
      left:-2px;
      background-size: 400%;
      z-index: -1;
      filter: blur(5px);
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      animation: glowing 20s linear infinite;
      opacity: 1;
      transition: opacity .3s ease-in-out;
      border-radius: inherit;
    }

    &:after {
      z-index: -1;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: inherit;
      left: 0;
      top: 0;
      border-radius: inherit;
    }

    .b_l_quad {
      position: absolute;
      left: -10px;
      top: -15px;
    }
    .b_l_quad .button_spots{
      @for $i from 1 through 20{
        &:nth-child(#{$i}){
          padding:random(3) + 2 + px;
          left: -25 + ($i * 12) + px;
          top: 50 + px;
        }
      }
      @for $i from 20 through 40{
        &:nth-child(#{$i}){
          padding:random(3) + 2 + px;
          left: -255 + ($i * 12) + px;
          top: -12 + px;
        }
      }
      @for $i from 40 through 46{
        &:nth-child(#{$i}){
          padding:random(3) + 2 + px;
          left: 204px;
          top: -488 + ($i * 12) + px;
        }
      }

      @for $i from 46 through 52{
        &:nth-child(#{$i}){
          padding:random(3) + 2 + px;
          left: -10px;
          top: -568 + ($i * 12) + px;
        }
      }
    }
    .button_spots{
      position:absolute;
      border-radius:100px;
      background:green;
      opacity:0;
      animation:opacity 1s;
      @for $i from 1 through 52{
        &:nth-of-type(#{$i}){
          transform-origin:90px - random(10) 20px - random(10);
          background:hsla(350 + random(399) ,57% - random(10) ,65%,1);
          box-shadow:0px 0px 10px rgba(255,255,255,0.12);
          transition:all 1s + random(10)/10;
        }
      }
    }

    &:hover .button_spots{
      @for $i from 1 through 19{
        &:nth-of-type(#{$i}){
          animation: spot-#{$i} .7s .4/random(10) + random(10)/10 + s linear infinite;
        }
      }
      @for $i from 20 through 40{
        &:nth-of-type(#{$i}){
          animation: spot-#{$i} .7s .4/random(10) + random(10)/10 + s linear infinite;
        }
      }
      @for $i from 40 through 46{
        &:nth-of-type(#{$i}){
          animation: spot-#{$i} .7s .4/random(10) + random(10)/10 + s linear infinite;
        }
      }
      @for $i from 46 through 54{
        &:nth-of-type(#{$i}){
          animation: spot-#{$i} .7s .4/random(10) + random(10)/10 + s linear infinite;
        }
      }
    }


    @for $i from 1 through 20{
      @keyframes spot-#{$i}{
        from{opacity:0;}
        to{transform:translateY(30px) translatex(-20px + $i*2);opacity:.6;}
      }
    }
    @for $i from 20 through 40{
      @keyframes spot-#{$i}{
        from{opacity:0;}
        to{transform:translateY(-30px) translatex(-50px + $i*2);opacity:.6;}
      }
    }
    @for $i from 40 through 45{
      @keyframes spot-#{$i}{
        from{opacity:0;}
        to{transform:translateY(-86px + $i*2) translatex(40px);opacity:.6;}
      }
    }
    @for $i from 46 through 54{
      @keyframes spot-#{$i}{
        from{opacity:0;}
        to{transform:translateY(-99px + $i*2) translatex(-40px);opacity:.6;}
      }
    }

    @keyframes opacity{
      from{}
      to{opacity:0;}
    }

    @keyframes rotate{
      from{opacity:.8}
      to{transform:rotate(360deg);opacity:.8}
    }

    @keyframes down{
      from{left:10px;}
      to{left:57px;}
    }

    @keyframes spew{
      from{opacity:0;}
      to{opacity:0.8;}
    }

    @keyframes final{
      from{opacity:1;}
      to{opacity:0;}
    }
    @keyframes finalbox{
      from{}
      to{width:50px;}
    }
    @keyframes tick{
      from{}
      to{transform:scale(1) rotate(-90deg)}
    }

    @keyframes glowing {
      0% { background-position: 0 0; }
      50% { background-position: 400% 0; }
      100% { background-position: 0 0; }
    }
  }
}


.json-example {
  font-size: 12px;
  color:#050038;
  background-color: #FFFBD1;
  padding: 20px;
}
</style>
